import { DOMAIN_API } from "@/constants/domains";

import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const createBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: `${DOMAIN_API}/api/`,
  });
};

const baseQueryWithReAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any,
) => {
  const baseQuery = createBaseQuery();
  const result = await baseQuery(args, api, extraOptions);

  
  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'products',
    'pages',
  ],
  endpoints: () => ({}),
});
