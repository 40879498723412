type ApplicationUrls =
  | 'root'
  | 'product';

const APPLICATION_URLS: Record<ApplicationUrls, string> = {
  root: '/',
  product: '/products/:id',
};


export {
  APPLICATION_URLS,
};
