import { lazy, Suspense } from 'react';
import {
  APPLICATION_URLS,
} from '@/constants/urls';

import Spinner from "@/components/Spinner/Spinner";

const HomePage = lazy(
  () =>
    import(
      /* webpackChunkName: "home" */
      '../pages/Home'
    ),
);

const ProductPage = lazy(
  () =>
    import(
      /* webpackChunkName: "home" */
      '../pages/Product'
    ),
);

const APPLICATION_ROUTES = [
  {
    exact: true,
    path: APPLICATION_URLS.root,
    element: (
      <Suspense fallback={<Spinner />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: APPLICATION_URLS.product,
    element: (
      <Suspense fallback={<Spinner />}>
        <ProductPage />
      </Suspense>
    ),
  },
];


export { APPLICATION_ROUTES };
