import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Spinner from "@/components/Spinner/Spinner";

import classNames from 'classnames';

import {
    APPLICATION_ROUTES,
} from './routes/routes';

import '@/assets/styles/index.sass';

function App() {
    return (
        <div className={classNames('app-container')}>
            <Router>
                <Suspense fallback={<Spinner />}>
                    <Routes>
                        {APPLICATION_ROUTES.map((item) => (
                            <Route key={item.path} {...item} />
                        ))}
                    </Routes>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
